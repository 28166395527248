let VideoEditor = {
    version: "11.0.2.21",
    releaseDD: "20",
    releaseMM: "02",
    releaseYYYY: "2025",
    size: "179.76",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let VideoReMaker = {
    version: "8.0.2.21",
    releaseDD: "20",
    releaseMM: "02",
    releaseYYYY: "2025",
    size: "69.41",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let VideoConverter = {
    version: "14.0.2.21",
    releaseDD: "20",
    releaseMM: "02",
    releaseYYYY: "2025",
    size: "84.83",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let MediaPlayer = {
    version: "6.0.2.21",
    releaseDD: "20",
    releaseMM: "02",
    releaseYYYY: "2025",
    size: "110.99",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AudioEditor = {
    version: "11.0.2.21",
    releaseDD: "20",
    releaseMM: "02",
    releaseYYYY: "2025",
    size: "50.86",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AudioConverter = {
    version: "11.0.2.21",
    releaseDD: "20",
    releaseMM: "02",
    releaseYYYY: "2025",
    size: "54.89",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AVSImageConverter = {
    version: "7.0.2.22",
    releaseDD: "21",
    releaseMM: "02",
    releaseYYYY: "2025",
    size: "41.04",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

export {VideoEditor, VideoReMaker, VideoConverter, MediaPlayer, AudioEditor, AudioConverter, AVSImageConverter};